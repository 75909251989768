import React from "react";
import _ from "lodash";
import moment from "moment";
import anchorme from "anchorme";
import { formatMomentRange } from "./utils-ts";

export function splitToParagraphs(str) {
  return (
    str
      ?.split?.("\n")
      .filter((line) => line)
      .map((line, index) => <p key={index}>{line}</p>) || []
  );
}

export function splitToParagraphsAllowEmptyLines(str) {
  return str?.split?.("\n").map((line, index) => <p key={index}>{line}</p>) || [];
}

export function splitToBr(str) {
  return (
    str
      ?.split?.("\n")
      .filter((line) => line)
      .map((line) => (
        <>
          {line}
          <br />
        </>
      )) || []
  );
}

export function splitToListItems(str) {
  if (!str) return [];
  const lines = str.split("\n");
  // eslint-disable-next-line react/no-danger
  return lines.map((line, index) => <li key={index} dangerouslySetInnerHTML={{ __html: line }} />);
}

/**
 * Combines the list of strings into one string separated by the given separator or comma by default. Discards
 * null values.
 */
export function combineToString(list, separator = ", ") {
  if (!_.isArray(list)) return null;
  return list.filter((v) => !_.isEmpty(v)).join(separator);
}

// Formats a date with year, e.g. "2019"
export function formatYear(inputDate) {
  if (inputDate == null) return "";

  const d = new Date(inputDate);

  return d.getFullYear();
}

// Formats a date with quarter and year, for example "Q1 2018"
export function formatQuarterAndYear(inputDate) {
  if (inputDate == null) return "";

  const d = new Date(inputDate);
  const month = d.getMonth(); // january = 0
  const quarter = Math.floor(month / 3) + 1;
  return `Q${quarter} ${d.getFullYear()}`;
}

export function formatQuarterAndYearRangeLegacy(startDate, endDate, t, ongoing, asYears) {
  const formatFunc = asYears ? formatYear : formatQuarterAndYear;

  const formatStart = formatFunc(startDate);
  let formatEnd = formatFunc(endDate);

  if (ongoing) formatEnd = t("renderTemplate.common.ongoing");

  if (formatStart === formatEnd) return formatStart;
  return `${formatStart}${formatEnd ? ` – ${formatEnd}` : ""}`;
}

export function formatDateRange(startDate, endDate) {
  return formatMomentRange(startDate, endDate, "L");
}

export function formatMonthAndYearRange(startDate, endDate, language = "en") {
  return formatMomentRange(startDate, endDate, "MMM YYYY", { language });
}

export function formatFullMonthAndYearRange(startDate, endDate, language = "en") {
  return formatMomentRange(startDate, endDate, "MMMM YYYY", { language });
}

export function formatQuarterAndYearRange(startDate, endDate, language = "en") {
  return formatMomentRange(startDate, endDate, "[Q]Q YYYY", { language });
}

export function formatYearRange(startDate, endDate, options = {}) {
  return formatMomentRange(startDate, endDate, "YYYY", options);
}

export const formatMonthYearRangeLegacy = (starttime, endtime, language = "en", t, ongoing, asYears) => {
  const format = asYears ? "YYYY" : "MMM YYYY";

  const start = starttime
    ? moment(starttime)
        .locale(language === "no" ? "nb" : language)
        .format(format)
    : "";
  let end = endtime
    ? moment(endtime)
        .locale(language === "no" ? "nb" : language)
        .format(format)
    : "";

  if (ongoing) end = t("renderTemplate.common.ongoing");

  return `${start}${start && end ? ` – ${end}` : ""}`;
};

export function formatMonthYearRange(
  startDate,
  endDate,
  { language = "en", ongoingIfNoEndDate = false, ongoingTranslation = "ongoing" }
) {
  if (ongoingIfNoEndDate && !endDate)
    return `${moment(startDate)
      .locale(language === "no" ? "nb" : language)
      .format("MMM YYYY")} – ${ongoingTranslation}`;
  return formatMomentRange(startDate, endDate, "MMM YYYY", { language });
}

export function formatYearMonthRange(starttime, endtime, language = "en", t, asYears) {
  const format = asYears ? "YYYY" : "YYYY MMM";
  return formatMomentRange(starttime, endtime, format, { language });
}

export function tagLinks(text) {
  return anchorme({
    input: text,
    options: {
      attributes: {
        target: "_blank",
      },
    },
  });
}

export function joinStrings(strings, separator = ", ") {
  return strings?.filter?.((s) => s).join(separator);
}

export function joinElements(elements, separator = <>, </>) {
  return elements
    ?.filter((e) => e)
    .reduce((prev, curr) => (prev.length === 0 ? [curr] : [...prev, separator, curr]), []);
}

// Get the editor field id of the given element by recursively checking the data-editor-field-id attribute
// in the parent nodes. This results in the full path to the element using the same syntax as the RJSF editor,
// for example "root_projects_2_title".
export function getEditorFieldId(element) {
  if (!element) return null;
  const editorFieldId = element.dataset?.editorFieldId ?? "";
  if (!element.parentNode || editorFieldId === "root") return editorFieldId;
  return getEditorFieldId(element.parentNode) + (editorFieldId ? "_" + editorFieldId : "");
}
